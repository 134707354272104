<template>
  <v-card>
    <v-card-text>
      <new-series :show="showNew" @close="showNew = false" @done="getCards" />
      <edit-series
        :show="showEdit"
        @close="showEdit = false"
        @done="getCards"
      ></edit-series>
      <v-bottom-sheet
        v-model="sheet"
        persistent
        :fullscreen="full"
        :scrollable="full"
      >
        <new-user ref="newUser" @close="sheet = false" />
      </v-bottom-sheet>
      <v-data-iterator
        :items="items"
        :items-per-page.sync="itemsPerPage"
        :page.sync="page"
        hide-default-footer
      >
        <template v-slot:header>
          <v-toolbar class="mb-2" color="primary" dark flat>
            <v-icon class="mr-5" v-if="!full">mdi-tag-multiple</v-icon>
            <v-toolbar-title v-if="!full">Tag Issue Manager</v-toolbar-title>
            <v-spacer />
            <span class="mr-4">
              {{ !full ? 'Page' : '' }} {{ page }} of {{ totalPages }}
            </span>
            <v-btn icon dark class="mr-1" @click="startPage">
              <v-icon>mdi-chevron-double-left</v-icon>
            </v-btn>
            <v-btn icon dark class="mr-1" @click="formerPage">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn icon dark class="ml-1" @click="nextPage">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
            <v-btn icon dark class="mr-1" @click="lastPage">
              <v-icon>mdi-chevron-double-right</v-icon>
            </v-btn>
          </v-toolbar>
          <v-progress-linear
            indeterminate
            color="primary"
            v-if="loading"
            striped
            height="5"
          ></v-progress-linear>
          <v-row justify="center">
            <v-col cols="12" md="3">
              <v-btn
                @click="showNew = true"
                text
                color="primary"
                outlined
                block
              >
                Add new record
              </v-btn>
            </v-col>
            <v-col cols="12" md="3">
              <v-btn
                @click="showEdit = true"
                text
                color="primary"
                outlined
                block
              >
                Edit records
              </v-btn>
            </v-col>
            <v-col cols="6" md="3">
              <v-spacer />
              <v-checkbox
                v-model="showLost"
                label="View Lost Tags"
                class="mt-0"
                hide-details
                dense
              />
              <v-spacer />
            </v-col>
            <v-col cols="6" md="3" class="text-right">
              <span class="grey--text">Items per page</span>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    dark
                    text
                    color="primary"
                    class="ml-2"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ itemsPerPage }}
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(number, index) in itemsPerPageArray"
                    :key="index"
                    @click="updateItemsPerPage(number)"
                  >
                    <v-list-item-title>{{ number }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
          <v-form ref="issuedForm" @submit="getInfo">
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="search"
                  clearable
                  outlined
                  dense
                  hide-details
                  prepend-inner-icon="mdi-magnify"
                  label="Tag Number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <auto-complete-users
                  label="User"
                  v-model="user"
                  editable
                  addNew
                  @edit="sheet = true"
                  @add="sheet = true"
                />
              </v-col>
              <v-col cols="12" md="2">
                <v-btn block color="primary" @click="getInfo" type="submit">
                  Submit
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </template>

        <template v-slot:default="{ items }">
          <v-row>
            <v-col
              v-for="item in items"
              :key="item._id"
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <v-card>
                <v-card-title class="subheading font-weight-bold">
                  <v-btn
                    icon
                    color="red"
                    v-if="selectedTag === item._id"
                    @click="selectedTag = null"
                  >
                    <v-icon>mdi-pencil-off</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    color="primary"
                    @click="selectedTag = item._id"
                    v-else
                  >
                    <v-icon>mdi-file-edit</v-icon>
                  </v-btn>
                  <span class="ml-2"> {{ item.number }} </span>
                  <v-spacer />
                  <v-btn icon color="red" @click="removeTag(item._id)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-card-title>

                <v-divider />

                <v-list v-if="selectedTag === item._id" dense>
                  <v-list-item>
                    <auto-complete-users
                      :label="'User'"
                      v-model="tagUserSelected"
                    />
                  </v-list-item>
                  <v-list-item class="mt-1">
                    <v-btn
                      text
                      block
                      color="primary"
                      outlined
                      @click="editCard(item)"
                    >
                      Save
                    </v-btn>
                  </v-list-item>
                </v-list>

                <v-list v-else dense>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon left>mdi-card-account-details</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      {{ getFullName(item) }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon left>mdi-map-marker</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      {{ getAddress(item) }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon left>mdi-card-account-phone</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      {{ getPhone(item) }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon left>mdi-clipboard-text-clock-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      {{ getPurchasedDate(item) }}
                    </v-list-item-content>
                  </v-list-item>
                </v-list>

                <v-divider />

                <v-list dense>
                  <v-list-item>
                    <v-checkbox
                      v-model="item.lost"
                      label="Report lost tag"
                      class="mt-0"
                      hide-details
                      dense
                      @click="selectedTag !== item._id && editCard(item)"
                    />
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import AutoCompleteUsers from '../../components/appComponents/AutoCompleteUsers.vue';
import { formatDate, notifyMessage } from '../../helpers/handler';
import NewUser from '../users/forms/sections/NewUser.vue';
import NewSeries from './NewSeries.vue';
import EditSeries from './EditSeries.vue';
import { CrewViewModel } from '../users/viewmodels';
export default {
  components: { AutoCompleteUsers, NewUser, NewSeries, EditSeries },
  name: 'tag-issuance',
  data: () => ({
    items: [],
    itemsPerPageArray: [10, 20, 50],
    itemsPerPage: 10,
    page: 1,
    totalPages: 0,
    sortBy: 'number',
    search: '',
    loading: false,
    showLost: false,
    user: new CrewViewModel().angler,
    sheet: false,
    showNew: false,
    showEdit: false,
    selectedTag: null,
    tagUserSelected: null
  }),
  async mounted() {
    await this.getCards();
  },
  computed: {
    filteredKeys() {
      return this.keys.filter((key) => key !== 'Name');
    },
    full() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },
  watch: {
    sheet: {
      handler: 'initEditUser',
      immediate: true
    }
  },
  methods: {
    ...mapActions('query', ['getIssuance']),
    ...mapActions('entry', ['editIssue', 'deleteIssue']),
    initEditUser(value) {
      if (value) {
        setTimeout(async () => {
          if (this.$refs.newUser) {
            await this.$refs.newUser.init(this.user?._id);
          }
        }, 100);
      }
    },
    async getCards() {
      this.loading = true;
      const response = await this.getIssuance({
        lost: this.showLost,
        id: this.user?._id,
        page: this.page,
        limit: this.itemsPerPage,
        number: this.search
      });
      this.items = response?.data?.docs || [];
      this.totalPages = response?.data?.totalPages || 0;
      this.loading = false;
    },
    getAddress(item) {
      const { user } = item || {};

      if (!user) {
        return 'No address registered';
      }

      const { address, city, zip, state, country } = user || {};
      return (
        [address, city, zip, state, country]
          .filter((str) => !!str)
          .join(', ') || 'No address registered'
      );
    },
    getFullName(item) {
      const { user } = item || {};
      const { firstName, lastName } = user || {};
      return firstName && lastName
        ? `${firstName} ${lastName}`
        : 'No name registered';
    },
    getPhone(item) {
      const { user } = item || {};
      if (!user) {
        return 'No phone registered';
      }
      return user.phone || 'No phone registered';
    },
    getPurchasedDate(item) {
      const { purchasedDate } = item || {};
      return formatDate(purchasedDate) || 'No date registered';
    },
    async nextPage() {
      if (this.page + 1 <= this.totalPages) {
        this.page += 1;
        await this.getCards();
      }
    },
    async formerPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
        await this.getCards();
      }
    },
    async startPage() {
      this.page = 1;
      await this.getCards();
    },
    async lastPage() {
      this.page = this.totalPages;
      await this.getCards();
    },
    async updateItemsPerPage(number) {
      this.itemsPerPage = number;
      await this.getCards();
    },
    async editCard(item) {
      if (item?._id) {
        if (this.tagUserSelected) {
          item.user = {
            ...item.user,
            firstName: this.tagUserSelected?.firstName,
            lastName: this.tagUserSelected?.lastName,
            id: this.tagUserSelected?._id,
            legacyId: this.tagUserSelected?.legacyId,
            memberId: this.tagUserSelected?.memberId,
            phone: this.tagUserSelected?.homePhone,
            address: this.tagUserSelected?.address?.line1,
            city: this.tagUserSelected?.address?.city,
            state: this.tagUserSelected?.address?.state,
            zip: this.tagUserSelected?.address?.zip
          };

          this.selectedTag = null;
          this.tagUserSelected = null;
        }

        const { data } = (await this.editIssue(item)) || {};
        notifyMessage(
          data?.success
            ? 'Tag edited successfully'
            : 'Unable to edit the selected tag'
        );
      }
    },
    async removeTag(id) {
      const { data } = (await this.deleteIssue({ id })) || {};
      data?.success && (await this.getCards());
      notifyMessage(
        data?.success ? 'Tag removed successfully' : 'Something went wrong'
      );
    },
    async getInfo(event) {
      if (event && event['preventDefault']) {
        event.preventDefault();
      }
      this.page = 1;
      await this.getCards();
    }
  }
};
</script>
