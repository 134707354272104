<template>
  <v-dialog
    v-model="show"
    :fullscreen="full"
    :scrollable="full"
    persistent
    width="360px"
  >
    <v-card>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-toolbar flat dark color="primary" max-height="100px">
        <v-toolbar-title> Edit Records </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="mb-5">
        <v-form ref="formIssues" class="mb-5">
          <v-row class="mb-5">
            <v-col>
              Update the user on the requested series or delete the records.
            </v-col>
            <v-col cols="12" class="mb-5">
              <auto-complete-users
                label="USER"
                :rules="rule"
                v-model="tagUserSelected"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="START NUMBER"
                v-model="startNumber"
                :rules="rule"
                type="number"
                step="any"
                min="1"
                clearable
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="END NUMBER"
                v-model="endNumber"
                type="number"
                step="any"
                min="1"
                :rules="endRule"
                clearable
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="SUFFIX"
                v-model="suffix"
                clearable
                outlined
                dense
                hide-details
                @keyup="uppercase"
              />
            </v-col>
            <v-col cols="12" v-if="startNumber">
              Range Preview: ({{ `${suffix || ''}${startNumber}` }} -
              {{ `${suffix || ''}${endNumber || startNumber}` }})
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="mt-5">
        <v-btn text color="red" @click="deleteRecords"> Delete </v-btn>
        <v-spacer />
        <v-btn text color="primary" @click="editRecords"> Edit </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import { getUserInfo } from '../../helpers/user';
import { notifyMessage, notifyConfirmation } from '../../helpers/notifications';
import AutoCompleteUsers from '../../components/appComponents/AutoCompleteUsers.vue';
export default {
  components: { AutoCompleteUsers },
  name: 'edit-series',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    startNumber: null,
    endNumber: null,
    suffix: null,
    tagUserSelected: null,
    rule: [(v) => !!v || 'Required'],
    overlay: false
  }),
  computed: {
    full() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    endRule() {
      return [
        (v) => {
          return (
            !v ||
            Number(v) >= Number(this.startNumber) ||
            'Same or greater value allowed'
          );
        }
      ];
    }
  },
  watch: {
    show: {
      handler: 'resetForm'
    }
  },
  methods: {
    ...mapActions('entry', ['editIssues', 'deleteIssues']),
    uppercase() {
      this.suffix = this.suffix.toUpperCase();
    },
    resetForm() {
      this.$refs.formIssues && this.$refs.formIssues.reset();
    },
    async editRecords() {
      const valid = this.$refs.formIssues && this.$refs.formIssues.validate();
      if (valid) {
        notifyConfirmation(
          'This action will update this record series',
          'Are you sure to proceed?',
          async () => {
            this.overlay = true;
            const user = getUserInfo();
            const response = await this.editIssues({
              userId: this.tagUserSelected?._id,
              startNumber: this.startNumber,
              endNumber: this.endNumber,
              suffix: this.suffix,
              enteredBy: user?.email
            });

            this.overlay = false;
            const { success, message } = response?.data || {};

            if (!success) {
              notifyMessage(message || 'Something went wrong');
              return;
            }

            notifyMessage('Edited successfully');
            this.tagUserSelected = null;
            this.$emit('done');
            this.$emit('close');
          }
        );
      }
    },
    async deleteRecords() {
      const valid = this.$refs.formIssues && this.$refs.formIssues.validate();
      if (valid) {
        notifyConfirmation(
          'This action will delete this record series',
          'Are you sure to proceed?',
          async () => {
            this.overlay = true;
            const user = getUserInfo();
            const response = await this.deleteIssues({
              userId: this.tagUserSelected?._id,
              startNumber: this.startNumber,
              endNumber: this.endNumber,
              enteredBy: user?.email
            });

            this.overlay = false;
            const { success, message } = response?.data || {};

            if (!success) {
              notifyMessage(message || 'Something went wrong');
              return;
            }

            notifyMessage('Deleted successfully');
            this.tagUserSelected = null;
            this.$emit('done');
            this.$emit('close');
          }
        );
      }
    }
  }
};
</script>
