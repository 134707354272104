<template>
  <v-dialog
    v-model="show"
    :fullscreen="full"
    :scrollable="full"
    persistent
    width="360px"
  >
    <v-card>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-toolbar flat dark color="primary" max-height="100px">
        <v-toolbar-title> Submit Records </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-form ref="formIssues" class="m-5">
          <v-row class="mb-5">
            <v-col cols="12" class="my-5">
              <auto-complete-users
                label="USER"
                :rules="rule"
                v-model="tagUserSelected"
              />
            </v-col>
            <v-col cols="12" class="mt-5">
              <v-text-field
                label="START NUMBER"
                v-model="startNumber"
                :rules="rule"
                clearable
                outlined
                dense
                hide-details
                type="number"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="END NUMBER"
                v-model="endNumber"
                :rules="rule"
                clearable
                outlined
                dense
                hide-details
                type="number"
              />
            </v-col>
            <v-col cols="12" class="mb-5">
              <v-text-field
                label="SUFFIX"
                v-model="suffix"
                clearable
                outlined
                dense
                hide-details
                @keyup="uppercase"
              />
            </v-col>
            <v-col cols="12">
              <date-picker
                v-model="purchasedDate"
                label="PURCHASED DATE"
                @clear="purchasedDate = null"
                hide-details
              />
            </v-col>
            <v-col cols="12" v-if="startNumber">
              Range Preview: ({{ previewRange }})
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="mt-5">
        <v-spacer />
        <v-btn text color="primary" @click="submitIssues"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import { getUserInfo } from '../../helpers/user';
import { notifyMessage } from '../../helpers/notifications';
import AutoCompleteUsers from '../../components/appComponents/AutoCompleteUsers.vue';
import DatePicker from '../../components/appComponents/DatePicker.vue';
import { fixTimezone } from '../../helpers/format';
export default {
  components: { AutoCompleteUsers, DatePicker },
  name: 'new-series',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    startNumber: null,
    endNumber: null,
    purchasedDate: new Date(),
    tagUserSelected: null,
    rule: [(v) => !!v || 'Required'],
    overlay: false,
    suffix: null
  }),
  computed: {
    full() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    previewRange() {
      const start = [this.suffix, this.startNumber].filter(Boolean).join('');
      const end = [this.suffix, this.endNumber || this.startNumber]
        .filter(Boolean)
        .join('');
      return `${start} - ${end}`;
    }
  },
  watch: {
    show: {
      handler: 'resetForm'
    }
  },
  methods: {
    ...mapActions('entry', ['saveIssue']),
    uppercase() {
      this.suffix = this.suffix.toUpperCase();
    },
    resetForm() {
      this.$refs.formIssues && this.$refs.formIssues.reset();
    },
    async submitIssues() {
      const valid = this.$refs.formIssues && this.$refs.formIssues.validate();
      if (valid) {
        this.overlay = true;
        const user = getUserInfo();
        const response = await this.saveIssue({
          userId: this.tagUserSelected?._id,
          startNumber: [this.suffix, this.startNumber].filter(Boolean).join(''),
          endNumber: [this.suffix, this.endNumber].filter(Boolean).join(''),
          enteredBy: user?.email,
          purchasedDate: fixTimezone(this.purchasedDate)
        });

        this.overlay = false;
        const { success, message } = response?.data || {};

        if (!success) {
          notifyMessage(message || 'Something went wrong');
          return;
        }

        notifyMessage('Submitted successfully');
        this.$refs.formIssues && this.$refs.formIssues.reset();
        this.tagUserSelected = null;
        this.$emit('done');
        this.$emit('close');
      }
    }
  }
};
</script>
